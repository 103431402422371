<template>
  <div>
    <side-bar-menu :menu="$section().admin"></side-bar-menu>
    <idle-timer></idle-timer>
    <b-row class="top-row border-bottom d-md-flex align-items-center">
      <b-col cols="9">
        <h4 v-if="this.entityUuid == ''">{{ $t('entity.create') }}</h4>
        <h4
          class="card-title"
          v-if="this.entityUuid != ''"
        >{{ $t('entity.update') }}</h4>
        <h6 class="mb-2 text-muted">{{ $t('entity.descTable') }}</h6>
      </b-col>
      <b-col cols="3">
        <el-button
          type="text"
          icon="el-icon-arrow-left"
          class="float-right"
          @click="$router.go(-1)"
        >{{ $t('action.back') }}</el-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        md="12"
        lg="8"
        class="mb-4 pb-4 border-bottom pl-5 mt-4 bg-white"
      >
        <form ref="form">
          <div
            class="row"
            v-if="entityUuid!=''"
          >
            <div class="col-md-12">
              <div
                class="alert alert-warning"
                role="alert"
                v-if="this.entityUuid != ''"
              >
                <font-awesome-icon
                  :icon="['fal', 'exclamation-circle']"
                  class="fa-lg"
                /> {{ $t('entity.warning') }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">

              <b-form-group
                type="text"
                :label="$t('entity.name')+ '*'"
              >

                <b-form-input
                  type="text"
                  class="form-control"
                  id="name"
                  v-model="form.name"
                  @blur="$v.form.name.$touch"
                ></b-form-input>
                <label
                  class="error"
                  v-if="!$v.form.name.required && $v.form.name.$dirty"
                >{{ $t('validate.required') }}
                </label>
                <label
                  class="error"
                  v-if="!$v.form.name.maxLength && $v.form.name.$dirty"
                >{{ $t('validate.maxLength', { val: '100' }) }}</label>
                <label
                  class="error"
                  v-if="!$v.form.name.minLength && $v.form.name.$dirty"
                >{{ $t('validate.minLength', { val: '2' }) }}</label>
              </b-form-group>

            </div>
            <div class="col-md-6">
              <b-form-group
                type="text"
                :label="$t('entity.shortname')+ '*'"
                :description="$t('entity.notice.shortname')"
              >
                <b-form-input
                  type="
                  text"
                  class="form-control border-orange"
                  id="shortname"
                  v-model="form.shortname"
                  @blur="$v.form.shortname.$touch"
                >
                </b-form-input>
                <small
                  id="emailHelp"
                  class="form-text text-muted"
                ></small>
                <label
                  class="error"
                  v-if="!$v.form.shortname.required && $v.form.shortname.$dirty"
                >{{ $t('validate.required') }}</label>
                <label
                  class="error"
                  v-if="!$v.form.shortname.maxLength && $v.form.shortname.$dirty"
                >{{ $t('validate.maxLength', { val: '12' }) }}</label>
                <label
                  class="error"
                  v-if="!$v.form.shortname.minLength && $v.form.shortname.$dirty"
                >{{ $t('validate.minLength', { val: '2' }) }}</label>
              </b-form-group>

            </div>

          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="acceptTerms"
                  v-model="form.acceptTerms"
                  @change="$v.form.acceptTerms.$touch"
                >
                <label
                  class="form-check-label"
                  for="acceptTerms"
                >{{ $t('auth.acceptTerms') }}*</label><br>
                <label
                  class="error"
                  v-if="!$v.form.acceptTerms.required && $v.form.acceptTerms.$dirty"
                >Accept Terms is required</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <small class="text-muted">* {{ $t('validate.requiredFields') }}</small>
              <button
                type="button"
                class="btn btn-primary float-right"
                @click="createEntity"
                v-if="entityUuid==''"
              >Add</button>
              <button
                type="button"
                class="btn btn-primary float-right mx-1"
                v-if="entityUuid!=''"
                @click="updateEntity"
              >Update</button>
              <button
                type="button"
                class="btn btn-secondary float-right mx-1"
                @click="$router.go(-1)"
                v-if="entityUuid!=''"
              >Cancel</button>
            </div>
          </div>

        </form>
      </b-col>
      <b-col
        cols="4"
        md="12"
        lg="4"
        class="mb-4 pb-4 border-bottom bg-white"
      ></b-col>
    </b-row>

    <modal-nature></modal-nature>

  </div>
</template>

<script>
import axiosResource from '@/utils/axiosResource';
import SideBarMenu from '@/components/SideBarMenu.vue';
import IdleTimer from '../../components/IdleTimer.vue';
import ModalNature from '@/components/Modals/ModalNature.vue';
import {
  required,
  sameAs,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators';

export default {
  components: {
    SideBarMenu,
    IdleTimer,
    ModalNature,
  },
  data() {
    return {
      entityUuid: '',
      form: {
        name: '',
        shortname: '',
        acceptTerms: '',
      },
      show: false,
      modalNature: false,
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(100),
      },
      shortname: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(12),
      },
      acceptTerms: {
        required,
        sameAs: sameAs(() => true),
      },
    },
  },
  mounted: function () {
    this.show = !this.show;
    if (this.$route.params.uuid != 'new') {
      this.entityUuid = this.$route.params.uuid;
      this.loadEntity();
    }
  },
  methods: {
    loadEntity() {
      axiosResource
        .get('/admin/entities/' + this.entityUuid)
        .then((response) => {
          this.$store.commit('loading', false);
          this.form.name = response.data.results.entity.name;
          this.form.shortname = response.data.results.entity.shortname;
        })
        .catch((err) => {
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    updateEntity() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.submitStatus = 'ERROR';
      } else {
        this.$store.commit('loading', true);
        axiosResource
          .put('/admin/entities/' + this.entityUuid, this.form)
          .then((response) => {
            this.$globalSwal.success('success.done', response.data.msg);
            this.loadEntity();
          })
          .catch((err) => {
            this.$globalSwal.error(err.response.data.msg);
          });

        this.submitStatus = 'PENDING';
        setTimeout(() => {
          this.submitStatus = 'OK';
        }, 500);
      }
    },
    createEntity() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.submitStatus = 'ERROR';
      } else {
        this.$store.commit('loading', true);
        // do your submit logic here

        // Send request
        axiosResource
          .post('/admin/entities/', this.form)
          .then((response) => {
            if (!response.data.success) {
              this.$globalSwal.error('error.sorry', response.data.message);
            } else {
              this.entityUuid = response.data.results;
              this.$globalSwal.success('success.done', response.data.message);
              this.loadEntity();
            }
          })
          .catch((err) => {
            this.$store.commit('loading', false);
            this.$globalSwal.serverError();
            if (err.response && err.response.status === 500) {
              this.$globalSwal.error(
                'error.oops',
                err.response.data.message,
                'error.persists'
              );
            }
          });

        this.submitStatus = 'PENDING';
        setTimeout(() => {
          this.submitStatus = 'OK';
        }, 500);
      }
    },
  },
};
</script>

        
        