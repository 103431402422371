<template>
  <div>
    <div class="row justify-content-end mt-3">
      <div class="d-flex align-items-center">
        <div class="mr-2">
          <span>Total: {{ this.total }}</span>
        </div>
        <div class="mx-2">
          <el-select
            v-model="selected"
            placeholder="Select"
            size="mini"
            class="base-pagination-select"
            @change="handleSizeChange($event)"
          >
            <el-option value="10">10</el-option>
            <el-option value="25">25</el-option>
            <el-option
              value="50"
              v-if="this.total > 25"
            >50</el-option>
            <el-option
              value="100"
              v-if="this.total > 50"
            >100</el-option>
            <el-option
              :value="this.total"
              v-if="this.total > 100"
            >Tout</el-option>
          </el-select>
        </div>
        <div class="ml-2">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="this.currentPage"
            :page-size="this.pageSize"
            layout="prev, pager, next"
            :total="this.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Pagination, Select, Option } from 'element-ui';

export default {
  name: 'BasePagination',
  data() {
    return {
      selected: 10,
    };
  },
  components: {
    [Pagination.name]: Pagination,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    total: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
  },
  methods: {
    handleSizeChange(e) {
      this.$emit('handleSizeChange', parseInt(e));
    },
    handleCurrentChange(val) {
      this.$emit('handleCurrentChange', val);
    },
  },
};
</script>
