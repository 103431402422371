<template>
  <b-modal
    id="modal-integrity"
    button-size="sm"
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    @shown="focusSmsDiv"
  >
    <template #modal-title>
      <div v-if="show">
        <h3 class="title mt-4">{{ $t('modal.integrity.verifyTitle') }}</h3>
        <b-row>
          <b-col>
            <p>{{ $t('modal.integrity.verifyBody') }}</p>

          </b-col>
        </b-row>
      </div>
      <div v-else>
        <h3 class="title mt-4">{{ $t('modal.integrity.enterCode') }}</h3>
      </div>
    </template>
    <div
      id="requestCode"
      v-if="show"
    >
      <b-row
        class="integrity-block sms align-items-center"
        id="smsContainer"
        @click="integritySms"
        @keydown.enter="integritySms"
        tabindex="0"
        ref="focusThis"
      >
        <b-col cols="1">

        </b-col>
        <b-col
          cols="2"
          class="d-flex justify-content-end"
        >
          <font-awesome-icon
            icon="fa-thin fa-message"
            size="2x"
          />
        </b-col>
        <b-col cols="9">
          <span>Text +XX XXXXXXX{{ phoneLastTwoDigits }}</span>
        </b-col>
      </b-row>
      <b-row
        class="integrity-block email align-items-center mb-3"
        @click="integrityEmail"
        @keydown.enter="integrityEmail"
        tabindex="0"
      >
        <b-col cols="1">
        </b-col>
        <b-col
          cols="2"
          class="d-flex justify-content-end"
        >
          <font-awesome-icon
            icon="fa-thin fa-at"
            size="2x"
          />
        </b-col>
        <b-col cols="9">
          <span>Email {{ username }}</span>
        </b-col>
      </b-row>
    </div>
    <div
      id="inputCode"
      v-else
    >
      <div v-if="type == 'sms'">
        <b-row>
          <b-col cols="1">
            <font-awesome-icon
              icon="fa-thin fa-message"
              size="2xl"
            />
          </b-col>
          <b-col cols="11">
            {{ $t('modal.integrity.smsTextA') }}{{ phoneLastTwoDigits }}. {{ $t('modal.integrity.smsTextB') }}
          </b-col>
        </b-row>

      </div>
      <div v-else>
        <b-row>
          <b-col cols="1">
            <font-awesome-icon
              icon="fa-thin fa-at"
              size="2xl"
            />
          </b-col>
          <b-col cols="11">
            {{ $t('modal.integrity.emailedTextA') }} {{ username }}. {{ $t('modal.integrity.emailedTextB') }}
          </b-col>
        </b-row>

      </div>
      <el-input
        id="codeInput"
        placeholder="Code"
        v-model="inputCode"
        class="mt-4 mb-5"
        @keyup.enter.native="verifyIntegrityCode"
      ></el-input>
      <p>{{ $t('modal.integrity.havingTrouble') }} <a
          href="#"
          @click="show = true"
        >{{ $t('modal.integrity.otherWay') }}</a> </p>
    </div>

    <template #modal-footer>
      <div
        class="w-100"
        v-if="show"
      >
        <b-link
          :to="{ path: '../../../home'}"
          replace
        >
          <b-button
            variant="secondary"
            class="float-right"
          >
            <!-- @click="$bvModal.hide('modal-integrity')" -->
            {{ $t('action.cancel') }}
          </b-button>
        </b-link>
      </div>
      <div
        class="w-100"
        v-else
      >

        <b-button
          id="verifyBtn"
          variant="primary"
          class="float-right"
          @click="verifyIntegrityCode"
        >
          {{ $t('modal.integrity.verifyBtn') }}
        </b-button>
        <b-link
          :to="{ path: '../../../home'}"
          replace
        >
          <b-button variant="secondary">
            <!-- @click="$bvModal.hide('modal-integrity')" -->
            {{ $t('action.cancel') }}
          </b-button>
        </b-link>
      </div>
    </template>
  </b-modal>

</template>

<script>
import axiosResource from '@/utils/axiosResource';
import swal from 'sweetalert2';
const swalBootsrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});

export default {
  name: 'modal-integrity',
  data() {
    return {
      show: true,
      fullscreenLoading: true,
      type: '',
      phone: '',
      phoneLastTwoDigits: '',
      username: '',
      code: '',
      inputCode: '',
    };
  },
  mounted() {
    this.fetchUserDetails();
    // var codeInput = document.getElementById("codeInput");

    // // Execute a function when the user presses a key on the keyboard
    // codeInput.addEventListener("keypress", function (event) {
    //   // If the user presses the "Enter" key on the keyboard
    //   if (event.key === "Enter") {
    //     // Cancel the default action, if needed
    //     event.preventDefault();
    //     // Trigger the button element with a click
    //     document.getElementById("verifyBtn").click();
    //   }
    // });
  },
  methods: {
    verifyIntegrityCode(e) {
      e.preventDefault();
      if (this.inputCode == this.code) {
        // User has provided verification code, issue integrity token then reload page
        axiosResource
          .get(`/integrity/token/`)
          .then((response) => {
            console.log(response);
            this.$router.go();
          })
          .catch((err) => {
            this.loading = false;
            this.$globalSwal.error(err.response.data.msg);
          });
      } else {
        swalBootsrap.fire({
          title: 'Oops',
          text: this.$t('error.integrity'),
          showConfirmButton: true,
          icon: 'info',
        });
      }
    },
    focusSmsDiv() {
      this.$refs.focusThis.focus();
    },
    fetchUserDetails() {
      const userUuid = this.$store.getters.loggedUser.uuid;
      axiosResource
        .get(`/admin/users/${userUuid}/`)
        .then((response) => {
          console.log(response);
          this.phone = response.data.user.phone;
          this.phoneLastTwoDigits = this.phone.substr(this.phone.length - 2);
          this.username = response.data.user.username;
          document.getElementById('smsContainer').focus();
          this.show = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    integritySms() {
      axiosResource
        .get(`/integrity/sms/${this.phone}/`)
        .then((response) => {
          this.show = false;
          this.type = 'sms';
          this.code = response.data.code;
        })
        .catch((err) => {
          this.loading = false;
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    integrityEmail() {
      axiosResource
        .get(`/integrity/email/${this.username}/`)
        .then((response) => {
          console.log(response);
          this.show = false;
          this.type = 'email';
          this.code = response.data.code;
        })
        .catch((err) => {
          this.loading = false;
          this.$globalSwal.error(err.response.data.msg);
        });
    },
  },
};
</script>
<style scopped>
#modal-integrity___BV_modal_content_.modal-content {
  border-radius: 0;
}
#modal-integrity___BV_modal_header_.modal-header {
  border-bottom: none;
}
#modal-integrity___BV_modal_footer_.modal-footer {
  border-top: none;
}
#modal-integrity___BV_modal_backdrop_.modal-backdrop {
  background-color: #409eff;
  opacity: 1;
} 
.title {
  font-weight: 800;
  color: black;
}
.integrity-block {
  height: 60px;
  cursor: pointer;
}
.sms {
  border-top: 1px dashed grey;
  border-bottom: 1px dashed grey;
}
.email {
  border-bottom: 1px dashed grey;
}
.integrity-block:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.integrity-block:focus {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
