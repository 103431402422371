<template>
  <b-modal
    id="modal-project"
    :title="$t('project.modal.'+mode+'Project')"
    :size="modalSize"
    button-size="sm"
    @show="onShow"
  >
    <b-alert
      variant="warning"
      :show="(mode == 'edit') ? true : false"
    >
      <font-awesome-icon
        :icon="['fal', 'exclamation-triangle']"
        class="fa-lg"
      /> {{ $t('building.warningEdit') }}
    </b-alert>
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col>

          <div
            class="alert alert-secondary"
            role="alert"
            v-if="this.mode != 'edit'"
          >
            PROJECT
          </div>

          <!-- REFERENCE -->

          <b-form-group
            type="text"
            id="input-group-2"
            :label="$t('project.modal.reference')+ '*'"
            label-for="ref"
            label-cols-sm="3"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="10"
            :description="$t('project.modal.descReference')"
          >

            <b-form-input
              id="ref"
              v-model="form.ref"
              v-uppercase
              @blur="$v.form.ref.$touch"
            ></b-form-input>
            <label
              class="error"
              v-if="!$v.form.ref.required && $v.form.ref.$dirty"
            >{{ $t('validate.required') }}</label>
            <label
              class="error"
              v-if="!$v.form.ref.maxLength && $v.form.ref.$dirty"
            >{{ $t('validate.maxLength', { val: '5' }) }}</label>
            <label
              class="error"
              v-if="!$v.form.ref.minLength && $v.form.ref.$dirty"
            >{{ $t('validate.minLength', { val: '1' }) }}</label>
          </b-form-group>

          <!-- NAME -->

          <b-form-group
            type="text"
            id="input-group-2"
            :label="$t('project.name')+ '*'"
            label-for="projectName"
            label-cols-sm="3"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="10"
          >
            <b-form-input
              id="projectName"
              v-model="form.projectName"
              type="text"
              @blur="$v.form.projectName.$touch"
            ></b-form-input>
            <label
              class="error"
              v-if="!$v.form.projectName.required && $v.form.projectName.$dirty"
            >{{ $t('validate.requiredFields') }}</label>
            <label
              class="error"
              v-if="!$v.form.projectName.maxLength && $v.form.projectName.$dirty"
            >{{ $t('validate.maxLength', { val: '30' }) }}</label>
            <label
              class="error"
              v-if="!$v.form.projectName.minLength && $v.form.projectName.$dirty"
            >{{ $t('validate.minLength', { val: '3' }) }}</label>
          </b-form-group>

          <!-- SHORTNAME -->

          <b-form-group
            type="text"
            id="input-group-2"
            :label="$t('project.shortname')+ '*'"
            label-for="projectShortname"
            label-cols-sm="3"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="10"
          >
            <b-form-input
              id="projectShortname"
              v-model="form.projectShortname"
              type="text"
              v-uppercase
              @blur="$v.form.projectShortname.$touch"
            ></b-form-input>
            <label
              class="error"
              v-if="!$v.form.projectShortname.required && $v.form.projectShortname.$dirty"
            >{{ $t('validate.requiredFields') }}</label>
            <label
              class="error"
              v-if="!$v.form.projectShortname.maxLength && $v.form.projectShortname.$dirty"
            >{{ $t('validate.maxLength', { val: '10' }) }}</label>
            <label
              class="error"
              v-if="!$v.form.projectShortname.minLength && $v.form.projectShortname.$dirty"
            >{{ $t('validate.minLength', { val: '3' }) }}</label>
          </b-form-group>

          <!-- LOCALITY -->

          <b-form-group
            type="text"
            id="input-group-2"
            :label="$t('project.modal.locality')+ '*'"
            label-for="name"
            label-cols-sm="3"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="10"
          >

            <b-form-input
              id="locality"
              v-model="form.locality"
              @blur="$v.form.locality.$touch"
            ></b-form-input>
            <label
              class="error"
              v-if="!$v.form.locality.required && $v.form.locality.$dirty"
            >{{ $t('validate.required') }}</label>
            <label
              class="error"
              v-if="!$v.form.locality.maxLength && $v.form.locality.$dirty"
            >{{ $t('validate.maxLength', { val: '20' }) }}</label>
          </b-form-group>

          <!-- COUNTRY -->

          <b-form-group
            type="text"
            id="input-group-2"
            :label="$t('project.modal.country')+ '*'"
            label-for="name"
            label-cols-sm="3"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="10"
          >

            <b-form-input
              id="country"
              v-model="form.country"
              @blur="$v.form.country.$touch"
            ></b-form-input>
            <label
              class="error"
              v-if="!$v.form.country.required && $v.form.country.$dirty"
            >{{ $t('validate.required') }}</label>
            <label
              class="error"
              v-if="!$v.form.country.maxLength && $v.form.country.$dirty"
            >{{ $t('validate.maxLength', { val: '20' }) }}</label>
          </b-form-group>
        </b-col>
        <b-col v-if="this.mode != 'edit'">
          <div
            class="alert alert-secondary"
            role="alert"
          >
            ENTITY
          </div>

          <!-- Entity name -->

          <b-form-group
            type="text"
            id="input-group-2"
            :label="$t('entity.name')+ '*'"
            label-for="entityName"
            label-cols-sm="3"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="10"
          >

            <b-form-input
              type="text"
              class="form-control"
              id="name"
              v-model="form.entityName"
              @blur="$v.form.entityName.$touch"
            ></b-form-input>
            <label
              class="error"
              v-if="!$v.form.entityName.required && $v.form.entityName.$dirty"
            >{{ $t('validate.required') }}
            </label>
            <label
              class="error"
              v-if="!$v.form.entityName.maxLength && $v.form.entityName.$dirty"
            >{{ $t('validate.maxLength', { val: '30' }) }}</label>
            <label
              class="error"
              v-if="!$v.form.entityName.minLength && $v.form.entityName.$dirty"
            >{{ $t('validate.maxLength', { val: '1' }) }}</label>
          </b-form-group>

          <!-- Entity shortname -->

          <b-form-group
            type="text"
            id="input-group-2"
            :label="$t('entity.shortname')+ '*'"
            :description="$t('entity.notice.shortname')"
            label-for="entityShortname"
            label-cols-sm="3"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="10"
          >
            <b-form-input
              type="text"
              class="form-control important"
              id="entityShortname"
              v-model="form.entityShortname"
              @blur="$v.form.entityShortname.$touch"
            >
            </b-form-input>
            <small
              id="emailHelp"
              class="form-text text-muted"
            ></small>
            <label
              class="error"
              v-if="!$v.form.entityShortname.required && $v.form.entityShortname.$dirty"
            >{{ $t('validate.required') }}</label>
            <label
              class="error"
              v-if="!$v.form.entityShortname.maxLength && $v.form.entityShortname.$dirty"
            >{{ $t('validate.maxLength', { val: '12' }) }}</label>
            <label
              class="error"
              v-if="!$v.form.entityShortname.minLength && $v.form.entityShortname.$dirty"
            >{{ $t('validate.minLength', { val: '1' }) }}</label>
          </b-form-group>

          <div
            class="alert alert-secondary"
            role="alert"
          >
            USER
          </div>

          <!-- USER -->

          <b-form-group
            type="text"
            id="input-group-2"
            :label="$t('filter.user')"
            label-cols-sm="3"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="10"
          >
            <b-form-input
              type="text"
              class="form-control important"
              v-model="form.userFirstnameLastname"
              disabled
            >
            </b-form-input>

          </b-form-group>

          <!-- NATURE -->

          <b-form-group
            id="input-group-2"
            label-cols-sm="3"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="10"
            label-for="nature"
          >
            <template v-slot:label>
              <div class="d-flex align-items-center">
                <span>{{ $t('auth.nature') }}*</span>
                <b-button
                  type="button"
                  v-b-popover.hover.focus.html="popoverContent"
                  variant="link"
                  class="project-info-button"
                >
                  <i class="el-icon-info"></i>
                </b-button>
              </div>
            </template>

            <el-select
              id="nature"
              v-model="form.nature.value"
              class="w-100"
            >
              <el-option
                v-for="item in form.nature.options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>

            <label
              class="error"
              v-if="!$v.form.nature.value.required && $v.form.nature.value.$dirty"
            >{{ $t('validate.required') }}</label>
          </b-form-group>
          <!-- <b-form-group
            id="input-group-entity"
            description="The nature defines interaction privileges of users."
            label-cols-sm="3"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="10"
          >
            <label for="nature">{{ $t('auth.nature') }}*
              <el-button
                type="text"
                icon="el-icon-info"
                v-b-modal="'modal-nature'"
                circle
                style="padding: 0px;"
              ></el-button>
            </label>
            <el-select
              v-model="form.nature.value"
              :placeholder="$t('auth.nature')"
            >
              <el-option
                v-for="item in form.nature.options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <label
              class="error"
              v-if="!$v.form.nature.value.required && $v.form.nature.value.$dirty"
            >{{ $t('validate.required') }}</label>
          </b-form-group> -->

        </b-col>
      </b-row>
    </b-form>

    <template #modal-footer="{ hide }">
      <div class="d-flex justify-content-between align-items-center w-100">
        <small class="text-muted">
          * {{ $t('validate.required') }}
        </small>
        <div>
          <b-button
            size="sm"
            variant="light"
            @click="hide"
          >
            {{ $t('action.cancel') }}
          </b-button>
          <b-button
            type="button"
            @click="onSubmit"
            size="sm"
            variant="primary"
          >
            {{ $t('action.save') }}
          </b-button>
        </div>
      </div>
    </template>

  </b-modal>

</template>

<script>
import axiosResource from '@/utils/axiosResource';
import {
  required,
  minLength,
  maxLength,
  sameAs,
} from 'vuelidate/lib/validators';

export default {
  name: 'modal-project',
  props: {
    mode: String,
    uuid: String,
    project: Object,
  },
  data() {
    return {
      projectUuid: '',
      form: {
        ref: '',
        projectName: '',
        projectShortname: '',
        locality: '',
        country: '',
        entityName: '',
        entityShortname: '',
        userFirstnameLastname: '',
        nature: {
          value: '',
          options: [
            { value: 'BM', label: 'BM' },
            { value: 'CONTROLLER', label: 'CONTROLLER' },
            { value: 'FIRM', label: 'FIRM' },
          ],
        },
      },
      userDetails: {
        username: '',
        firstname: '',
        lastname: '',
        nature: '',
        type: '',
        phone: '',
        entity: '',
        since: '',
      },
      popoverContent: `
        <div>
          <p style="margin: 0;">
            <strong>${this.$t('auth.nature')}</strong> ${this.$t(
        'project.modal.nature_popover_title'
      )}
          </p>
          <p style="margin: 0;">
            ${this.$t('project.modal.nature_popover_desc')}
          </p>
          <ul style="padding-left: 20px; margin: 0;">
            <li>${this.$t('modal.nature.ep')}: ${this.$t(
        'modal.nature.desc_ep'
      )}</li>
            <li>${this.$t('modal.nature.controller')}: ${this.$t(
        'modal.nature.desc_controller'
      )}</li>
            <li>${this.$t('modal.nature.firm')}: ${this.$t(
        'modal.nature.desc_firm'
      )}</li>
          </ul>
          <p style="margin: 0;">
            ${this.$t('project.modal.nature_popover_doc')}
          </p>
        </div>`,
      // form: { ...this.project },
    };
  },
  validations() {
    const isEditMode = this.mode === 'edit';
    return {
      form: {
        ref: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(5),
        },
        projectName: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(30),
        },
        projectShortname: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(10),
        },
        locality: {
          required,
          maxLength: maxLength(20),
        },
        country: {
          required,
          maxLength: maxLength(20),
        },
        entityName: isEditMode
          ? {}
          : {
              required,
              minLength: minLength(1),
              maxLength: maxLength(30),
            },
        entityShortname: isEditMode
          ? {}
          : {
              required,
              minLength: minLength(1),
              maxLength: maxLength(10),
            },
        nature: isEditMode
          ? {}
          : {
              value: {
                required,
              },
            },
      },
      acceptTerms: {
        required,
        sameAs: sameAs(() => true),
      },
    };
  },
  computed: {
    modalSize() {
      return this.mode === 'new' ? 'xl' : null;
    },
  },
  methods: {
    loadUser() {
      const userUuid = this.$store.getters.loggedUser.uuid;
      this.$store.commit('loading', true);
      axiosResource
        .get('/admin/users/' + userUuid)
        .then((response) => {
          this.form.userFirstnameLastname =
            response.data.user.name.firstname +
            ' ' +
            response.data.user.name.lastname;
          this.$store.commit('loading', false);
        })
        .catch((err) => {
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    onShow() {
      this.loadUser();
      const clgStyle = 'color: blue;font-size:14px;font-weight: 400;';
      console.log('%c' + 'ModalProject onShow()', clgStyle);
      console.log('Mode:', this.mode);
      console.log('UUID:', this.uuid);
      if (this.mode === 'edit' && this.uuid) {
        // Load existing project data
        this.prepareForm();
        console.log('edit ' + this.uuid);
      } else {
        this.$v.$reset();
        this.form.ref = '';
        this.form.projectName = '';
        this.form.projectShortname = '';
        this.form.locality = '';
        this.form.country = '';
        this.form.entityName = '';
        this.form.entityShortname = '';
        this.form.nature.value = '';
      }
    },
    prepareForm() {
      const clgStyle = 'color: red;';
      console.log('%c' + `Prepare form to ${this.mode}`, clgStyle);
      console.log(this.project);
      this.form.ref = this.project.ref;
      this.form.projectName = this.project.projectName;
      this.form.projectShortname = this.project.projectShortname;
      this.form.locality = this.project.locality;
      this.form.country = this.project.country;
      this.form.entityName = this.project.entityName;
      this.form.entityShortname = this.project.entityShortname;
      this.form.nature.value = this.project.nature;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.submitStatus = 'ERROR';
      } else {
        this.$store.commit('loading', true);

        if (this.mode === 'edit') {
          this.$store
            .dispatch('updateProject', {
              uuid: this.project.uuid,
              projectData: this.form,
            })
            .then(() => {
              this.$bvModal.hide('modal-project');
            })
            .catch((err) => {
              console.log(err);
              this.$globalSwal.error('test');
            });
        } else {
          this.$store
            .dispatch('createProject', this.form)
            .then((newProject) => {
              this.$bvModal.hide('modal-project');
            })
            .catch((err) => {
              console.log(err);
              this.$globalSwal.serverError();
            });
        }
      }
    },
    saveProject() {
      if (this.mode === 'edit') {
        this.$store.commit('loading', true);
        this.$store
          .dispatch('updateProject', {
            uuid: this.project.uuid,
            projectData: this.form,
          })
          .then(() => {
            this.$bvModal.hide('modal-project');
          })
          .catch((err) => {
            this.$globalSwal.error(err.response.data.msg);
          });
      } else {
        this.$store.commit('loading', true);
        this.$store
          .dispatch('createProject', this.form)
          .then((newProject) => {
            this.$bvModal.hide('modal-project');
          })
          .catch((err) => {
            this.$globalSwal.error(err.response.data.msg);
          });
      }
    },
    createProject() {
      this.$store.commit('loading', true);

      this.$store
        .dispatch('createProject', this.form)
        .then((newProject) => {
          this.$bvModal.hide('modal-project');
        })
        .catch((err) => {
          this.$globalSwal.error(err.response.data.msg);
        });
    },
  },
};
</script>
<style scoped>
/* Add any additional styles here */
.popover {
  max-width: 300px; /* Adjust as needed */
}
.project-info-button {
  padding: 0; 
  margin-left: 5px;
}
</style>

