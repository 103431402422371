<template>
  <b-row>
    <b-col
      cols="12"
      md="12"
      lg="12"
      class="mt-4 mb-4 nopadding"
    >
      <side-bar-menu :menu="$section().admin"></side-bar-menu>
      <idle-timer></idle-timer>
      <b-row class="nomargin">
        <b-col
          cols="9"
          class="pl-5 mb-2"
        >
          <h4 class="card-title">{{ $t('user.manage', { name: details.acronym4digits }) }}</h4>
          <h6 class="mb-2 text-muted">{{ $t('user.userListSubtitle') }}</h6>
        </b-col>
        <b-col cols="3">
          <el-button
            type="text"
            icon="el-icon-arrow-left"
            class="float-right"
            @click="$router.push(`../userlist`);"
          >{{ $t('action.back') }}</el-button>
        </b-col>

      </b-row>
      <zoom-center-transition
        :duration="200"
        mode="out-in"
      >
        <div v-show="show">
          <b-row class="nomargin">
            <b-col
              cols="12"
              md="8"
              class="nopadding mb-2 pl-4"
            >
              <b-card>
                <template #header>
                  <b-row>
                    <b-col>
                      <h4>{{ $t('user.details') }}</h4>
                    </b-col>
                    <b-col>
                      <el-button
                        icon="el-icon-edit"
                        size="mini"
                        class="float-right"
                        @click="$router.push(`../usercreateedit/${userUuid}`);"
                      >{{ $t('action.edit') }}</el-button>
                    </b-col>
                  </b-row>
                </template>

                <!-- <b-row>
                  <b-col cols="3">
                    <h5>Id</h5>
                  </b-col>
                  <b-col cols="9">{{ details.userId.digit5 }} <span class="text-muted">({{ userUuid }})</span></b-col>
                </b-row>
                <hr> -->
                <b-row>
                  <b-col cols="3">
                    <h5>{{ $t('user.name') }}</h5>
                  </b-col>
                  <b-col cols="9">{{ details.lastname }} {{ details.firstname }} ({{ details.acronym4digits }})</b-col>
                </b-row>
                <hr>
                <b-row>
                  <b-col cols="3">
                    <h5>{{ $t('user.email') }}</h5>
                  </b-col>
                  <b-col cols="9">{{ details.username }}</b-col>
                </b-row>
                <hr>
                <b-row>
                  <b-col cols="3">
                    <h5>{{ $t('user.phone') }}</h5>
                  </b-col>
                  <b-col cols="9">{{ details.phone }}</b-col>
                </b-row>
                <hr>
                <b-row>
                  <b-col cols="3">
                    <h5>{{ $t('user.nature') }}</h5>
                  </b-col>
                  <b-col cols="9">
                    <el-tag :type="details.type">{{ details.nature }}</el-tag>
                  </b-col>
                </b-row>
                <hr>
                <b-row>
                  <b-col cols="3">
                    <h5>{{ $t('user.entity') }}</h5>
                  </b-col>
                  <b-col cols="9">{{ details.entity.name }} ({{ details.entity.shortname }})</b-col>
                </b-row>
                <hr>
                <b-row>
                  <b-col cols="3">
                    <h5 class="text-danger">{{ $t('user.role.text') }}</h5>
                  </b-col>
                  <b-col cols="9">
                    <span v-if="details.role === 'admin'">
                      {{ $t('user.role.admin') }}
                    </span>
                    <span v-else>{{ $t('user.role.user') }}</span>
                  </b-col>
                </b-row>
                <hr>
                <b-row>
                  <b-col cols="3">
                    <h5>{{ $t('user.by') }}</h5>
                  </b-col>
                  <b-col cols="9">{{ details.by }}</b-col>
                </b-row>
                <hr>
                <b-row>
                  <b-col cols="3">
                    <h5>{{ $t('user.the') }}</h5>
                  </b-col>
                  <b-col cols="9">{{ details.since }}</b-col>
                </b-row>

              </b-card>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="nopadding mb-2"
            >
              <b-card>
                <template #header>
                  <b-row>
                    <b-col>
                      <h4>{{ $t('user.status') }}</h4>
                    </b-col>
                  </b-row>
                </template>
                <div class="text-center w-100">
                  <font-awesome-icon
                    :icon="['fad', status.icon]"
                    class="fa-5x mb-3"
                    :class="status.color"
                  />
                  <h2 :class="status.color">{{ status.text }}</h2>
                </div>

                <!-- :class="`fa-5x mb-3 ${ statusColor }`" -->
              </b-card>
              <br>
              <div v-if="status.raw != 'BLOCKED'">
                <el-button
                  type="info"
                  icon="el-icon-message"
                  class="mt-1 w-100"
                  @click="inviteUser"
                >{{ $t('user.sendInvite') }}</el-button>
                <br>
              </div>
              <div v-if="status.raw != 'BLOCKED'">
                <el-button
                  type="danger"
                  @click="notReady()"
                  icon="el-icon-circle-close"
                  class="mt-1 w-100"
                >{{ $t('action.block') }}</el-button>
              </div>
              <div v-if="status.raw == 'BLOCKED'">
                <el-button
                  type="primary"
                  icon="el-icon-refresh-right"
                  class="mt-1 w-100"
                >Unblock</el-button>
              </div>
              <div>
                <el-button
                  v-if="status.raw == 'PENDING'"
                  type="danger"
                  icon="el-icon-delete"
                  class="mt-1 w-100"
                >{{ $t('action.delete') }}</el-button>
              </div>
            </b-col>
          </b-row>

        </div>
      </zoom-center-transition>
    </b-col>
  </b-row>
</template>

<script>
import { ZoomCenterTransition } from 'vue2-transitions';
import SideBarMenu from '../../components/SideBarMenu.vue';
import IdleTimer from '../../components/IdleTimer.vue';
import axiosResource from '@/utils/axiosResource';
import swal from 'sweetalert2';
const swalBootsrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    denyButton: 'btn btn-info btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});

export default {
  components: {
    SideBarMenu,
    ZoomCenterTransition,
    IdleTimer,
  },
  data() {
    return {
      show: false,
      userUuid: '',
      details: {
        userId: '',
        acronym4digits: '',
        firstname: '',
        lastname: '',
        username: '',
        phone: '',
        entity: '',
        nature: '',
        type: '',
        role: '',
        by: '',
        since: '',
      },
      status: {
        raw: '',
        text: '',
        color: 'el-danger',
        icon: 'ban',
      },
      siteAccess: [],
    };
  },
  mounted: function () {
    this.show = !this.show;
    this.userUuid = this.$route.params.uuid;
    this.loadUser();
    console.log('siteAccess mounted', this.siteAccess);
    this.siteAccess = this.$store.getters.siteAccess;
    console.log('Store values ', this.$store.getters.siteAccess);
    console.log('siteAccess mounted after store filling', this.siteAccess);
  },
  // beforeCreate() {
  //   // Set siteAccess
  //   this.siteAccess = this.$store.state.siteAccess;
  //   console.log('siteAccess beforeCreate', this.siteAccess);
  // },
  methods: {
    notReady() {
      swalBootsrap.fire({
        title: 'Oops',
        text: this.$t('error.notReady'),
        showConfirmButton: true,
        icon: 'info',
      });
    },
    inviteUser() {
      this.$store.commit('loading', true);
      axiosResource
        .post(
          `/emails/invitation/${this.userUuid}/${this.details.userId.raw}/${this.details.username}/alexandre.geiss@hotmail.com`
        )
        .then((response) => {
          if (response.data.success) {
            this.$store.commit('loading', false);
            this.$globalSwal.success('success.done', response.data.msg);
            console.log(response);
          }
        })
        .catch((err) => {
          this.$store.commit('loading', false);
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    editUser() {
      console.log(`userID in Manage: ${this.userId}`);
      this.$router.push({
        name: 'usercreateedit',
        params: { userId: this.userId },
      });
    },
    loadUser() {
      this.$store.commit('loading', true);
      axiosResource
        .get('/admin/users/' + this.userUuid)
        .then((response) => {
          console.log(response);
          this.$store.commit('loading', false);
          // this.details.id = response.data.user.id.digit5;
          this.details.userId = response.data.user.id;
          this.details.firstname = response.data.user.name.firstname;
          this.details.lastname = response.data.user.name.lastname;

          this.details.acronym4digits = response.data.user.name.acronym4digits;
          this.details.username = response.data.user.username;
          this.details.phone = response.data.user.phone;
          this.details.nature = response.data.user.nature;
          this.details.role = response.data.user.role;

          switch (this.details.nature) {
            case 'BM':
              this.details.type = '';
              break;
            case 'CONTROLLER':
              this.details.type = 'success';
              break;
            case 'FIRM':
              this.details.type = 'info';
              break;
            default:
              break;
          }
          this.details.entity = response.data.user.entity;
          this.details.by = `${response.data.by.lastname} ${response.data.by.firstname} (${response.data.by.acronym4digits})`;
          this.details.since = response.data.user.dateCreated;

          this.status.raw = response.data.user.status;
          switch (response.data.user.status) {
            case 'ACTIVE':
              this.status.text = this.$tc('status.ACTIVE');
              this.status.icon = 'check-circle';
              this.status.color = 'el-success';
              break;
            case 'PENDING':
              this.status.text = this.$tc('status.PENDING');
              this.status.icon = 'hourglass-start';
              this.status.color = 'el-secondary';
              break;
            case 'BLOCKED':
              this.status.text = this.$tc('status.BLOCKED');
              this.status.icon = 'ban';
              this.status.color = 'el-danger';
              break;
            default:
              break;
          }
        })
        .catch((err) => {
          this.$globalSwal.error(err.response.data.msg);
        });
    },
  },
};
</script>

<style scoped>
.form-group {
    margin-bottom: 0.2rem;
}
</style>
