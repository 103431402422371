<template>
  <div>
    <side-bar-menu :menu="$section().admin"></side-bar-menu>
    <idle-timer></idle-timer>
    <b-row class="top-row border-bottom d-md-flex align-items-center">
      <b-col cols="9">
        <h4 v-if="this.flagId == 'new'">{{ $t('flag.create') }}</h4>
        <h4
          class="card-title"
          v-if="this.flagId != 'new'"
        >{{ $t('flag.update') }}</h4>
        <h6 class="mb-2 text-muted">{{ $t('flag.descTable') }}</h6>
      </b-col>
      <b-col cols="3">
        <el-button
          type="text"
          icon="el-icon-arrow-left"
          class="float-right"
          @click="$router.go(-1)"
        >{{ $t('action.back') }}</el-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        md="12"
        lg="8"
        class="mb-4 pb-4 border-bottom pl-5 mt-4 bg-white"
      >
        <form ref="form">
          <div
            class="row"
            v-if="flagId!=''"
          >
            <div class="col-md-12">
              <div
                class="alert alert-warning"
                role="alert"
                v-if="this.flagId != 'new'"
              >
                <font-awesome-icon
                  :icon="['fal', 'exclamation-circle']"
                  class="fa-lg"
                /> {{ $t('flag.warning') }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <b-form-group
                type="text"
                :label="$t('flag.content')+ '*'"
              >
                <b-form-input
                  type="text"
                  maxlength="30"
                  class="form-control"
                  id="content"
                  v-model="form.content"
                  @blur="$v.form.content.$touch"
                ></b-form-input>
                <label
                  class="error"
                  v-if="!$v.form.content.required && $v.form.content.$dirty"
                >{{ $t('validate.required') }}
                </label>
                <label
                  class="error"
                  v-if="!$v.form.content.maxLength && $v.form.content.$dirty"
                >{{ $t('validate.maxLength', { val: '30' }) }}</label>
                <label
                  class="error"
                  v-if="!$v.form.content.minLength && $v.form.content.$dirty"
                >{{ $t('validate.maxLength', { val: '2' }) }}</label>
              </b-form-group>

            </div>
            <div class="col-md-6">

              <b-form-group
                type="text"
                :label="$t('flag.result')"
              >
                <!-- <div class="fakeInput"> -->
                <span
                  :style="form.style"
                  class="flag"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-flag"
                    class="mr-2"
                  /> {{ this.form.content }}
                </span>
                <!-- </div> -->
              </b-form-group>

            </div>
          </div>

          <!-- <div class="row">
            <div class="col-md-6">
              <b-form-group
                type="text"
                :label="$t('flag.style')"
              >
                <b-form-checkbox
                  v-model="checked"
                  name="check-button"
                  switch
                >
                  Italic <b>(Checked: {{ checked }})</b>
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="checked"
                  name="check-button"
                  switch
                >
                  Bold <b>(Checked: {{ checked }})</b>
                </b-form-checkbox>
              </b-form-group>

            </div>
          </div> -->

          <div class="row">
            <div class="col-md-6">
              <b-form-group
                type="text"
                :label="$t('flag.textColor')"
              >
                <compact-picker
                  @input="updateValue"
                  :value="form.txtColor"
                  v-model="form.txtColor"
                  :palette="[
                    '#4D4D4D', '#999999', '#FFFFFF', '#FF392B', '#FF8B00', '#FFDA02', '#DADF00', '#93DF00', '#33CFCB', '#43DBFF', '#B1A0FF', '#FF9CFF',
                    '#333333', '#808080', '#CCCCCC', '#E60F00', '#F36B01', '#FFC002', '#ADBE00', '#43BF00', '#05A8A6', '#009FE6', '#7B64FF', '#FF00FF',
                    '#000000', '#666666', '#B3B3B3', '#AE0000', '#D44702', '#FF9800', '#7E8900', '#004E31', '#027B7E', '#0064B7', '#653294', '#BB02A3',
                  ]"
                ></compact-picker>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <b-form-group
                type="text"
                :label="$t('flag.bgColor')"
              >
                <compact-picker
                  @input="updateValue"
                  :value="form.bgColor"
                  v-model="form.bgColor"
                  :palette="[
                    '#4D4D4D', '#999999', '#FFFFFF', '#FF392B', '#FF8B00', '#FFDA02', '#DADF00', '#93DF00', '#33CFCB', '#43DBFF', '#B1A0FF', '#FF9CFF',
                    '#333333', '#808080', '#CCCCCC', '#E60F00', '#F36B01', '#FFC002', '#ADBE00', '#43BF00', '#05A8A6', '#009FE6', '#7B64FF', '#FF00FF',
                    '#000000', '#666666', '#B3B3B3', '#AE0000', '#D44702', '#FF9800', '#7E8900', '#004E31', '#027B7E', '#0064B7', '#653294', '#BB02A3',
                  ]"
                ></compact-picker>
              </b-form-group>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-6">
              <div class="form-group form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="acceptTerms"
                  v-model="form.acceptTerms"
                  @change="$v.form.acceptTerms.$touch"
                >
                <label
                  class="form-check-label"
                  for="acceptTerms"
                >{{ $t('auth.confirm') }}*</label><br>
                <label
                  class="error"
                  v-if="!$v.form.acceptTerms.required && $v.form.acceptTerms.$dirty"
                >Accept Terms is required</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <small class="text-muted">* {{ $t('validate.requiredFields') }}</small>
              <button
                type="button"
                class="btn btn-primary float-right"
                @click="createFlag"
                v-if="flagId=='new'"
              >Add</button>
              <button
                type="button"
                class="btn btn-primary float-right mx-1"
                v-if="flagId!='new'"
                @click="updateFlag"
              >Update</button>
              <button
                type="button"
                class="btn btn-secondary float-right mx-1"
                @click="$router.go(-1)"
                v-if="flagId!=''"
              >Cancel</button>
            </div>
          </div>

        </form>
      </b-col>
      <b-col
        cols="12"
        md="12"
        lg="4"
        class="mb-4 pb-4 border-bottom bg-white"
      ></b-col>
    </b-row>

    <modal-nature></modal-nature>

  </div>
</template>

<script>
import axiosResource from '@/utils/axiosResource';
import SideBarMenu from '@/components/SideBarMenu.vue';
import IdleTimer from '../../components/IdleTimer.vue';
import ModalNature from '@/components/Modals/ModalNature.vue';
import { Compact } from 'vue-color';
import {
  required,
  sameAs,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators';

export default {
  components: {
    SideBarMenu,
    IdleTimer,
    ModalNature,
    'compact-picker': Compact,
  },
  data() {
    return {
      flagId: this.$route.params.flagId,
      form: {
        content: this.$t('flag.contentInit'),
        txtColor: '#333333',
        fontStyle: 'italic',
        fontWeight: '400',
        bgColor: '#CCCCCC',
        acceptTerms: '',
        style: '',
      },
      modalNature: false,
    };
  },
  validations: {
    form: {
      content: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(30),
      },
      acceptTerms: {
        required,
        sameAs: sameAs(() => true),
      },
    },
  },
  mounted: function () {
    if (this.$route.params.flagId != 'new') {
      this.flagId = this.$route.params.id;
      this.loadFlag();
    }
    this.form.style = `color: ${this.form.txtColor}; background-color: ${this.form.bgColor}; font-style: ${this.form.fontStyle}; font-weight: ${this.form.fontWeight};`;
  },
  methods: {
    updateValue() {
      this.form.style = `color: ${this.form.txtColor.hex}; background-color: ${this.form.bgColor.hex}; font-style: ${this.form.fontStyle}; font-weight: ${this.form.fontWeight};`;
    },
    loadFlag() {
      axiosResource
        .get(`/admin/flag/single/${this.flagId}`)
        .then((response) => {
          this.$store.commit('loading', false);
          console.log(response);
          this.form.content = response.data.results[0].content;
          this.form.txtColor = response.data.results[0].txtColor;
          this.form.bgColor = response.data.results[0].bgColor;
          this.form.style = `color: ${this.form.txtColor}; background-color: ${this.form.bgColor}; font-style: ${this.form.fontStyle}; font-weight: ${this.form.fontWeight};`;
        })
        .catch((err) => {
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    updateFlag() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.submitStatus = 'ERROR';
      } else {
        this.$store.commit('loading', true);
        axiosResource
          .put(`/admin/flag/${this.flagId}`, this.form)
          .then((response) => {
            this.$globalSwal.success('success.done', response.data.msg);
            this.loadFlag();
          })
          .catch((err) => {
            this.$globalSwal.error(err.response.data.msg);
          });

        this.submitStatus = 'PENDING';
        setTimeout(() => {
          this.submitStatus = 'OK';
        }, 500);
      }
    },
    createFlag() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.submitStatus = 'ERROR';
      } else {
        this.$store.commit('loading', true);
        // do your submit logic here
        const projectId = this.$store.getters.currentProject.id;
        const projectUuid = this.$store.getters.currentProject.uuid;
        // Send request
        axiosResource
          .post(`/admin/flag/${projectId}/${projectUuid}`, this.form)
          .then((response) => {
            if (!response.data.success) {
              this.$globalSwal.error('error.sorry', response.data.message);
            } else {
              this.$store.commit('loading', false);
              this.fladId = response.data.results;
              this.$globalSwal.success('success.done', response.data.message);
              this.loadFlag();
            }
          })
          .catch((err) => {
            this.$store.commit('loading', false);
            this.$globalSwal.serverError();
            if (err.response && err.response.status === 500) {
              this.$globalSwal.error(
                'error.oops',
                err.response.data.message,
                'error.persists'
              );
            }
          });
      }
    },
  },
};
</script>
<style>
.flag {
  padding: 6px;
  padding-right: 12px;
  padding-left: 6px;
  font-size: 16px; 
  display: inline-block; 
  widows: 120px;
  height: calc(1.5em + 0.675rem);
  border-radius: 3px;
  vertical-align: middle;
}
/* .fakeInput {
  border: 0.0625rem solid #ced4da;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.875rem);
  padding: 0.2rem 0.75rem;
} */
</style>

        
        