<template>
  <div>
    <side-bar-menu :menu="$section().admin"></side-bar-menu>
    <idle-timer></idle-timer>
    <b-row class="top-row border-bottom d-md-flex align-items-center">
      <b-col cols="9">
        <h4 v-if="this.userUuid == ''">{{ $t('user.create') }}</h4>
        <h4
          class="card-title"
          v-if="this.userUuid != ''"
        >{{ $t('user.update') }}</h4>
        <h6 class="mb-2 text-muted">{{ $t('user.userListSubtitle') }}</h6>
      </b-col>
      <b-col cols="3">
        <el-button
          type="text"
          icon="el-icon-arrow-left"
          class="float-right"
          @click="$router.go(-1)"
        >{{ $t('action.back') }}</el-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        md="12"
        lg="8"
        class="mb-4 pb-4 border-bottom pl-5 mt-4 bg-white"
      >

        <b-form>
          <!-- First row -->
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                id="input-group-firstname"
                label-for="firstname"
              >
                <label for="firstname">{{ $t('auth.firstname') }}*</label>
                <b-form-input
                  id="firstname"
                  type="text"
                  v-model="form.firstname"
                  @blur="$v.form.firstname.$touch"
                ></b-form-input>
                <label
                  class="error"
                  v-if="!$v.form.firstname.required && $v.form.firstname.$dirty"
                >{{ $t('validate.required') }}
                </label>
                <label
                  class="error"
                  v-if="!$v.form.firstname.maxLength && $v.form.firstname.$dirty"
                >{{ $t('validate.maxLength', { val: '30' }) }}</label>
                <label
                  class="error"
                  v-if="!$v.form.firstname.minLength && $v.form.firstname.$dirty"
                >{{ $t('validate.minLength', { val: '1' }) }}</label>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group id="input-group-lastname">
                <label for="lastname">{{ $t('auth.lastname') }}*</label>
                <b-form-input
                  id="lastname"
                  v-model="form.lastname"
                  @blur="$v.form.lastname.$touch"
                  type="text"
                ></b-form-input>
                <label
                  class="error"
                  v-if="!$v.form.lastname.required && $v.form.lastname.$dirty"
                >{{ $t('validate.required') }}
                </label>
                <label
                  class="error"
                  v-if="!$v.form.lastname.maxLength && $v.form.lastname.$dirty"
                >{{ $t('validate.maxLength', { val: '30' }) }}</label>
                <label
                  class="error"
                  v-if="!$v.form.lastname.minLength && $v.form.lastname.$dirty"
                >{{ $t('validate.minLength', { val: '1' }) }}</label>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Second row -->
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                id="input-group-username"
                label-for="username"
                :description="(form.status == 'ACTIVE') ? 'Changing email is not permitted for ACTIVE users.' : ''"
              >
                <label for="username">{{ $t('auth.email') }}*</label>
                <b-form-input
                  id="username"
                  type="email"
                  v-model="form.username"
                  @blur="$v.form.username.$touch"
                  :disabled="(form.status == 'ACTIVE') ? true : false"
                ></b-form-input>
                <label
                  class="error"
                  v-if="(!$v.form.username.required || !$v.form.username.email) && $v.form.username.$dirty"
                >{{ $t('validate.email') }}</label>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                id="input-group-phone"
                label-for="phone"
              >
                <label for="phone">{{ $t('auth.phone') }}*</label>
                <vue-tel-input
                  id="phone"
                  v-model="form.phone"
                  @blur="$v.form.phone.$touch()"
                ></vue-tel-input>
                <label
                  class="error"
                  v-if="(!$v.form.phone.required) && $v.form.phone.$dirty"
                >{{ $t('validate.required') }}</label>
                <label
                  class="error"
                  v-if="!$v.form.phone.customNumeric && $v.form.phone.$dirty"
                >{{ $t('validate.phone') }}</label>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Third row -->

          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group id="input-group-entity">
                <label for="entity">{{ $t('auth.entity') }}*</label>
                <el-select
                  :placeholder="$t('auth.entity')"
                  v-model="form.entity.value"
                  filterable
                  @blur="$v.form.entity.value.$touch()"
                  data-is-search="true"
                  class="w-100"
                >
                  <el-option
                    v-for="item in form.entity.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <label
                  class="error"
                  v-if="!$v.form.entity.value.required && $v.form.entity.value.$dirty"
                >{{ $t('validate.required') }}</label>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                id="input-group-entity"
                description="The nature defines interaction privileges of users."
              >
                <label for="nature">{{ $t('auth.nature') }}*
                  <el-button
                    type="text"
                    icon="el-icon-info"
                    v-b-modal="'modal-nature'"
                    circle
                    class="p-0"
                  ></el-button>
                </label>
                <el-select
                  class="w-100"
                  v-model="form.nature.value"
                  :placeholder="$t('auth.nature')"
                >
                  <el-option
                    v-for="item in form.nature.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <label
                  class="error"
                  v-if="!$v.form.nature.value.required && $v.form.nature.value.$dirty"
                >{{ $t('validate.required') }}</label>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Fourth row -->

          <b-row>
            <b-col cols="12">
              <b-form-checkbox
                id="admin"
                name="admin"
                class="text-danger"
                v-model="form.admin"
                @change="setAsAdmin"
                :checked="form.admin"
              >
                {{ $t('user.makeAdmin') }}
                <el-button
                  type="text"
                  class="text-danger p-0"
                  icon="el-icon-question"
                  v-b-modal="'modal-admin'"
                  circle
                ></el-button>
              </b-form-checkbox>

            </b-col>
            <b-col
              cols="12"
              md="6"
            ></b-col>
          </b-row>

          <!-- Fifth row -->

          <b-row class="mt-4">
            <b-col cols="12">
              <b-form-checkbox
                id="acceptTerms"
                name="acceptTerms"
                v-model="form.acceptTerms"
              >
                {{ $t('auth.acceptTerms') }}*
                <el-button
                  type="text"
                  round
                  v-b-modal="'modal-TnC'"
                  size="small"
                  class="p-0"
                >
                  Terms & conditions
                </el-button>
              </b-form-checkbox>
              <label
                class="error"
                v-if="!$v.form.acceptTerms.required && $v.form.acceptTerms.$dirty"
              >{{ $t('validate.required') }}</label>

            </b-col>
            <b-col
              cols="12"
              md="6"
            ></b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col
              cols="12"
              md="6"
            ><small class="text-muted">* {{ $t('validate.required') }}</small></b-col>
            <b-col
              cols="12"
              md="6"
              class="text-right"
            >
              <b-button
                variant="primary"
                @click="createUser"
                v-if="userUuid==''"
              >{{ $t('action.add') }}</b-button>
              <b-button
                variant="light"
                class="mx-2"
                @click="$router.go(-1)"
                v-if="userUuid!=''"
              >{{ $t('action.cancel') }}</b-button>
              <b-button
                variant="primary"
                @click="updateUser"
                v-if="userUuid!=''"
              >{{ $t('action.update') }}</b-button>

            </b-col>
          </b-row>

        </b-form>

      </b-col>
      <b-col
        cols="4"
        md="12"
        lg="4"
        class="mb-4 pb-4 border-bottom bg-white"
      ></b-col>
    </b-row>
    <modal-nature></modal-nature>
    <modal-admin></modal-admin>
    <modal-TnC></modal-TnC>
  </div>
</template>

<script>
import SideBarMenu from '../../components/SideBarMenu.vue';
import IdleTimer from '../../components/IdleTimer.vue';
import axiosResource from '@/utils/axiosResource';
import ModalNature from '@/components/Modals/ModalNature.vue';
import ModalAdmin from '@/components/Modals/ModalAdmin.vue';
import ModalTnC from '@/components/Modals/ModalTnC.vue';
import {
  helpers,
  required,
  email,
  sameAs,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators';

// Custom regex validator for phone number
const customNumeric = helpers.regex('customNumeric', /^\+[0-9 ]+/);
const customAlpha = helpers.regex('customAlpha', /^[a-zA-Z\- ]*$/);

export default {
  components: {
    SideBarMenu,
    IdleTimer,
    ModalNature,
    ModalAdmin,
    ModalTnC,
  },
  data() {
    return {
      show: false,
      userUuid: '',
      form: {
        userUuid: '',
        firstname: '',
        lastname: '',
        username: '',
        phone: '',
        entity: {
          value: '',
          options: [],
        },
        nature: {
          value: '',
          options: [
            { value: 'BM', label: 'BM' },
            { value: 'CONTROLLER', label: 'CONTROLLER' },
            { value: 'FIRM', label: 'FIRM' },
          ],
        },
        role: 'user',
        admin: false,
        acceptTerms: '',
        byUserUuid: '',
        status: '',
      },
    };
  },
  validations: {
    form: {
      firstname: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(30),
      },
      lastname: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(30),
      },
      username: {
        required,
        email,
      },
      phone: {
        required,
        customNumeric,
      },
      entity: {
        value: {
          required,
        },
      },
      nature: {
        value: {
          required,
        },
      },
      acceptTerms: {
        required,
        sameAs: sameAs(() => true),
      },
    },
  },
  mounted() {
    this.show = !this.show;
    this.loadEntities();
    if (this.$route.params.uuid != 'new') {
      this.userUuid = this.$route.params.uuid;
      this.loadUser();
    }
  },
  methods: {
    setAsAdmin(e) {
      if (e) {
        this.form.role = 'admin';
      } else {
        this.form.role = 'user';
      }
    },
    loadUser() {
      console.log('loadUser: ' + this.userUuid);

      axiosResource
        .get('/admin/users/' + this.userUuid)
        .then((response) => {
          this.$store.commit('loading', false);
          if (response.data.success == false) {
            this.$globalSwal.error('error.sorry', response.data.msg);
          } else {
            console.log(response);
            this.form.userId = response.data.user.id.raw;
            this.form.firstname = response.data.user.name.firstname;
            this.form.lastname = response.data.user.name.lastname;
            this.form.username = response.data.user.username;
            this.form.phone = response.data.user.phone;

            if (response.data.user.role == 'admin') {
              this.form.role = 'admin';
              this.form.admin = true;
            } else {
              this.form.role = 'user';
              this.form.admin = false;
            }
            this.form.entity.value = response.data.user.entity.uuid;
            this.form.nature.value = response.data.user.nature;
            this.form.status = response.data.user.status;
          }
        })
        .catch((err) => {
          this.$store.commit('loading', false);
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    updateUser() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.submitStatus = 'ERROR';
      } else {
        this.$store.commit('loading', true);
        axiosResource
          .put('/admin/users/' + this.userUuid, this.form)
          .then((response) => {
            if (!response.data.success) {
              this.$globalSwal.error('error.sorry', response.data.msg);
            } else {
              this.$globalSwal.success('success.done', response.data.msg);
              this.loadUser();
            }
          })
          .catch((err) => {
            this.$store.commit('loading', false);
            this.$globalSwal.error(err.response.data.msg);
          });
        this.submitStatus = 'PENDING';
        setTimeout(() => {
          this.submitStatus = 'OK';
        }, 500);
      }
    },
    createUser() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.submitStatus = 'ERROR';
      } else {
        this.$store.commit('loading', true);
        axiosResource
          .post('/admin/users/register/', this.form)
          .then((response) => {
            this.$store.commit('loading', false);
            if (!response.data.success) {
              this.$globalSwal.error('error.sorry', response.data.msg);
            } else {
              console.log(response.data.results);
              this.userUuid = response.data.results;
              this.$globalSwal.success('success.done', response.data.msg);
              this.$router.push({
                name: 'usermanage',
                params: { uuid: this.userUuid },
              });
            }
          })
          .catch((err) => {
            this.$store.commit('loading', false);
            this.$globalSwal.error(err.response.data.msg);
          });
        this.submitStatus = 'PENDING';
        setTimeout(() => {
          this.submitStatus = 'OK';
        }, 500);
      }
    },
    loadEntities() {
      axiosResource
        .get('/admin/entities/')
        .then((response) => {
          var entities = [];
          var entityUuid = '';
          var entityName = '';

          for (var i = 0; i < response.data.length; i++) {
            entityUuid = response.data[i].entity.uuid;
            entityName = response.data[i].entity.name;
            entities.push({
              value: entityUuid,
              label: entityName,
            });
          }

          this.form.entity.options = entities;
        })
        .catch((err) => {
          this.$store.commit('loading', false);
          this.$globalSwal.error(err.response.data.msg);
        });
    },
  },
};
</script>