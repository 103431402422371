<template>
  <b-modal
    id="modal-TnC"
    :title="$t('legal.modalTitle')"
    size="md"
    button-size="sm"
  >
    <div class="modal-body-fixed">
      <h1>Terms and Conditions</h1>

      <h3>Introduction</h3>
      <p>Welcome to GOSO.cloud, a deficiency management software designed for data acquisition and management of reports. GOSO.cloud is developed and published by App Engineering Design (A.E.D). By accessing or using GOSO.cloud, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, you must not use GOSO.cloud.</p>

      <h3>Definitions</h3>
      <p>
        "Software" refers to GOSO.cloud, the deficiency management software provided by A.E.D.
        "Company", "we", "us", and "our" refer to App Engineering Design (A.E.D).
        "User", "you", and "your" refer to the individual or entity using the Software.
        "Personal Data" means any information relating to an identified or identifiable individual, as defined by the General Data Protection Regulation (GDPR).
      </p>

      <h3>Acceptance of Terms</h3>
      <p>By using the Software, you agree to comply with and be legally bound by these Terms and Conditions, our Privacy Policy, and any additional terms and conditions that may apply. If you do not agree to these terms, you must not use the Software.</p>

      <h3>User Obligations</h3>
      <h4>Account Registration</h4>
      <p>
        You must provide accurate and complete information when creating an account.
        You are responsible for maintaining the confidentiality of your account credentials.
        You must notify us immediately of any unauthorized use of your account.
      </p>

      <h4>Use of the Software</h4>
      <p>
        You agree to use the Software in compliance with all applicable laws and regulations.
        You must not use the Software for any illegal or unauthorized purpose.
        You are responsible for the data you upload and the reports you generate using the Software.
      </p>

      <h3>Data Protection and Privacy</h3>
      <p>
        We are committed to protecting your Personal Data. Please refer to our Privacy Policy for details on how we collect, use, and protect your information.
        By using the Software, you consent to the collection and use of your data as outlined in our Privacy Policy.
        You are responsible for ensuring that any data you input into the Software is in compliance with GDPR and other applicable data protection laws.
      </p>

      <h3>Intellectual Property</h3>
      <p>
        The Software and all associated materials, including but not limited to text, graphics, logos, and software code, are the property of A.E.D and are protected by intellectual property laws.
        You are granted a limited, non-exclusive, non-transferable license to use the Software for its intended purpose.
        You must not copy, modify, distribute, or create derivative works of the Software without our prior written consent.
      </p>

      <h3>Limitation of Liability</h3>
      <p>
        The Software is provided "as is" without any warranties of any kind, either express or implied.
        To the fullest extent permitted by law, A.E.D shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Software.
      </p>

      <h3>Indemnification</h3>
      <p>
        You agree to indemnify and hold harmless A.E.D, its officers, directors, employees, and agents from any claims, liabilities, damages, losses, or expenses arising out of your use of the Software or your violation of these Terms and Conditions.
      </p>

      <h3>Termination</h3>
      <p>
        We reserve the right to suspend or terminate your access to the Software at any time, without notice, for conduct that we believe violates these Terms and Conditions or is harmful to other users, us, or third parties.
      </p>

      <h3>Changes to Terms</h3>
      <p>
        We may modify these Terms and Conditions at any time. Any changes will be posted on our website, and your continued use of the Software after such modifications will constitute your acceptance of the new terms.
      </p>

      <h3>Governing Law</h3>
      <p>These Terms and Conditions are governed by and construed in accordance with the laws of France, without regard to its conflict of law principles.</p>

      <h3>Contact Information</h3>

      <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>

      <p>App Engineering Design (A.E.D)</p>
      <p>contact@goso.cloud</p>

      <h1>Privacy Policy</h1>

      <h3>Introduction</h3>

      <p>App Engineering Design (A.E.D) is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and protect your personal data when you use GOSO.cloud, our deficiency management software. By using GOSO.cloud, you consent to the data practices described in this policy.</p>

      <h3>Data Controller</h3>

      <p>A.E.D is the data controller responsible for the personal data we process. If you have any questions about this Privacy Policy or our data practices, please contact us at:</p>

      <p>App Engineering Design (A.E.D)</p>
      <p>contact@goso.cloud</p>

      <h3>Data We Collect</h3>

      <h4>Information You Provide</h4>

      <p>Account Information: When you register for an account, we collect your name, email address, and other contact details.
        Data Entries: Any information you input into the software, including reports and data for deficiency management.
      </p>

      <h4>Information We Collect Automatically</h4>

      <p>Usage Data: We collect information about how you use GOSO.cloud, including access times, pages viewed, and interactions with our software.
        Device Information: We collect information about the device you use to access our software, including the hardware model, operating system, and IP address.</p>

      <h3>How We Use Your Data</h3>

      <p>We use the data we collect for the following purposes:</p>
      <p>Providing Services: To operate and maintain GOSO.cloud, including processing your data entries and generating reports.
        Improving Services: To understand how users interact with our software, allowing us to enhance functionality and user experience.
        Communications: To send you updates, security alerts, and support messages related to your use of GOSO.cloud.
        Compliance: To comply with legal obligations and enforce our terms and conditions.</p>

      <h3>Legal Basis for Processing</h3>

      <p>Our legal basis for collecting and using your personal data depends on the specific context in which we collect it. Generally, we rely on:</p>

      <p>Contractual Necessity: Processing your data to fulfill our contractual obligations to you.
        Legitimate Interests: For purposes of improving our services, ensuring security, and communicating with you.
        Consent: When you have given explicit consent for specific processing activities.</p>

      <h3>Data Sharing and Disclosure</h3>

      <p>We do not share your personal data with third parties except in the following circumstances:</p>

      <p>Service Providers: We may share data with third-party vendors who perform services on our behalf, such as hosting providers and analytics services.
        Legal Requirements: We may disclose data if required by law or to respond to legal processes.
        Business Transfers: In the event of a merger, acquisition, or sale of assets, we may transfer your data to the involved parties.</p>

      <h3>Data Retention</h3>

      <p>We retain your personal data only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, or as required by law. When we no longer need your data, we will securely delete or anonymize it.</p>

      <h3>Your Data Protection Rights</h3>

      <p>Under GDPR, you have the following rights:</p>

      <p>Access: You can request access to your personal data we hold.
        Rectification: You can request correction of inaccurate or incomplete data.
        Erasure: You can request deletion of your personal data.
        Restriction: You can request restriction of processing under certain conditions.
        Portability: You can request a copy of your data in a commonly used format.
        Objection: You can object to processing based on legitimate interests or direct marketing.</p>

      <p>To exercise any of these rights, please contact us at contact@goso.cloud.</p>

      <h3>Data Security</h3>

      <p>We implement appropriate technical and organizational measures to protect your personal data against unauthorized access, alteration, disclosure, or destruction. However, no internet-based service can be 100% secure, and we cannot guarantee absolute security of your data.</p>

      <h3>Changes to This Privacy Policy</h3>

      <p>We may update this Privacy Policy from time to time. Any changes will be posted on our website, and we will notify you of significant changes. Your continued use of GOSO.cloud after such modifications will constitute your acknowledgment of the updated policy.</p>

      <h3>Contact Information</h3>

      <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>

      <p>App Engineering Design (A.E.D)</p>
      <p>contact@goso.cloud</p>

    </div>

    <template #modal-footer="{ hide }">
      <b-button
        size="sm"
        variant="light"
        @click="hide()"
      >
        {{ $t('action.close') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalTnC',
};
</script>

<style scoped>
.modal-body-fixed {
  max-height: 400px; /* Set a fixed height */
  overflow-y: auto; /* Make the content scrollable */
}
</style>
