<template>
  <b-modal
    id="modal-nature"
    title="Nature"
    button-size="sm"
  >
    <p>{{ $t('modal.nature.instruction')}}</p>
    <el-table
      :data="natureData"
      class="w-100"
      size="small"
    >
      <el-table-column
        prop="type"
        label="Type"
      >
        <template slot-scope="scope">
          <el-tag
            :type="typeNature(scope.row.type)"
            disable-transitions
          >{{scope.row.type}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="def"
        label="Definition"
      >
      </el-table-column>

    </el-table>
    <template #modal-footer="{ hide }">
      <b-button
        size="sm"
        variant="light"
        @click="hide()"
      >
        {{ $t('action.close') }}
      </b-button>
    </template>
  </b-modal>

</template>

<script>
export default {
  name: 'modal-nature',
  data() {
    return {
      natureData: [
        {
          type: this.$t('modal.nature.ep'),
          def: this.$t('modal.nature.desc_ep'),
        },
        {
          type: this.$t('modal.nature.controller'),
          def: this.$t('modal.nature.desc_controller'),
        },
        {
          type: this.$t('modal.nature.firm'),
          def: this.$t('modal.nature.desc_firm'),
        },
      ],
    };
  },
  methods: {
    typeNature(val) {
      console.log(val);
      switch (val) {
        case 'BM':
          return 'primary';
        case 'CONTROLLER':
          return 'success';
        case 'FIRM':
          return 'info';
        default:
          return 'danger';
      }
    },
  },
};
</script>
