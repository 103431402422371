<template>
  <div>
    <side-bar-menu :menu="$section().admin"></side-bar-menu>
    <idle-timer></idle-timer>
    <b-row class="top-row border-bottom d-md-flex align-items-center">
      <b-col
        class="d-none d-md-block"
        cols="2"
        md="2"
      >
        <span class="custom-font-size-1rem">{{ qtt }} {{ $tc('entity.entity', qtt).toUpperCase() }}</span>
      </b-col>
      <b-col
        cols="8"
        md="6"
      >
        <el-input
          v-model="search"
          size="large"
          prefix-icon="el-icon-search"
          :placeholder="$t('components.typeToSearch')"
        />
      </b-col>
      <b-col
        cols="4"
        md="4"
      >
        <el-button
          type="primary"
          @click="createNew"
          class="float-right"
        >{{ $t('action.add') }}</el-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        md="12"
        lg="12"
        class="mb-4 pb-4 border-bottom bg-white"
      >

        <div class="mt-4">
          <el-table
            :data="searchInTable"
            style="width: 100%"
            size="large"
            v-loading="loading"
          >
            <el-table-column
              :width="isMobile ? 66 : 100"
              fixed
            >
              <template
                slot="header"
                slot-scope="{}"
              >
              </template>
              <template slot-scope="scope">
                <el-button
                  type="plain"
                  size="mini"
                  @click="handleLookup(scope.$index, scope.row)"
                >
                  <font-awesome-icon
                    icon="fa-light fa-cog"
                    class="d-block d-md-none"
                  />
                  <span class="d-none d-md-block">Manage</span>
                </el-button>
              </template>
            </el-table-column>

            <el-table-column
              :label="$t('entity.table.name')"
              prop="entity.name"
              sortable
              show-overflow-tooltip
              width="260"
            >
            </el-table-column>
            <el-table-column
              :label="$t('entity.table.shortname')"
              prop="entity.shortname"
              sortable
            >
            </el-table-column>
            <el-table-column
              :label="$t('entity.table.count')"
              prop="count"
              :formatter="formatter_numberOfUsers"
              sortable
            >
            </el-table-column>

          </el-table>
          <base-pagination
            class="mb-4"
            :total="this.tableData.length"
            :currentPage="this.currentPage"
            :pageSize="this.pageSize"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
          />
        </div>
      </b-col>
    </b-row>
    <modal-integrity></modal-integrity>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import SideBarMenu from '../../components/SideBarMenu.vue';
import IdleTimer from '../../components/IdleTimer.vue';
import ModalIntegrity from '@/components/Modals/ModalIntegrity.vue';
import axiosResource from '@/utils/axiosResource';
import swal from 'sweetalert2';
import BasePagination from '../../components/BasePagination.vue';
import { isMobileMixin } from '../../mixins/isMobileMixin';

export default {
  mixins: [isMobileMixin],
  components: {
    BasePagination,
    SideBarMenu,
    IdleTimer,
    ModalIntegrity,
  },
  data() {
    return {
      show: false,
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      search: '',
      loading: false,
      accessToken: '',
      qtt: 0,
    };
  },
  mounted() {
    this.loading = true;
    this.show = !this.show;
    const { path } = this.$route;
    this.SAVE_CONTEXT(path);
    this.fetchData();
  },
  computed: {
    searchInTable() {
      if (this.search != '') {
        return this.tableData.filter(
          (data) =>
            !this.search ||
            data.entity.name
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            data.entity.shortname
              .toLowerCase()
              .includes(this.search.toLowerCase())
        );
      } else {
        return this.pagedTableData;
      }
    },
    pagedTableData() {
      return this.tableData.slice(
        this.pageSize * this.currentPage - this.pageSize,
        this.pageSize * this.currentPage
      );
    },
  },
  methods: {
    ...mapMutations(['SAVE_CONTEXT']),

    handleSizeChange(val) {
      // console.log(`${val} items per page`);
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      // console.log(`current page: ${val}`);
      this.currentPage = val;
    },
    handleLookup(index, row) {
      const uuid = row.entity.uuid;
      this.$router.push({ name: 'entitymanage', params: { uuid } });
    },
    handleDelete(index, row) {
      usersInEntity(row.entity.id.raw);
    },
    createNew() {
      this.$router.push({ name: 'entitycreateedit', params: { uuid: 'new' } });
    },
    formatter_numberOfUsers(row) {
      return row.count + ' ' + this.$tc('entity.user', row.count);
    },
    fetchData() {
      console.log('FETCH DATA');
      // INITIAL CALL TO GET ACCESS TOKEN
      axiosResource
        .get('/admin/entities/', { withCredentials: true })
        .then((response) => {
          this.tableData = response.data;
          console.log(response.data);
          this.loading = false;
          this.qtt = response.data.length;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response.data.msg == 'error.noIntegrityToken') {
            this.$bvModal.show('modal-integrity');
          } else {
            this.$globalSwal.error(err.response.data.msg);
          }
        });
    },
  },
};

const usersInEntity = async (entityId) => {
  axiosResource
    .get(`/admin/entities/${entityId}/users`)
    .then((response) => {
      if (!response.data.success) {
        this.$globalSwal.error('error.sorry', response.data.message);
        console.log('=============== ERROR HERE1');
        //   console.log(err);
      } else {
        console.log(response.data.results);
        if (response.data.results.length != 0) {
          swal.fire(
            'Impossible de supprimer',
            'Des utilisateurs appartiennent à cette entité.',
            'error'
          );
        } else {
          swal
            .fire({
              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete it!',
            })
            .then((result) => {
              if (result.isConfirmed) {
                deleteEntity(entityId);
              }
            });
        }
      }
    })
    .catch((err) => {
      console.log('=============== ERROR HERE2');
      console.log(err);
      if (err.response && err.response.status === 500) {
        console.log('=============== ERROR HERE3');
        // this.$globalSwal.error(
        //   "error.oops",
        //   err.response.data.message,
        //   "error.persists"
        // );
      }

      this.$globalSwal.serverError();
    });
};

const deleteEntity = async (entityId) => {
  axiosResource
    .delete(`/admin/entities/${entityId}`)
    .then((response) => {
      if (!response.data.success) {
        console.log(response.data.msg);
        this.$globalSwal.serverError();
        // this.$globalSwal.error('error.sorry', response.data.message);
      } else {
        console.log('DELETE results:');
        console.log(response.data.msg);

        swal.fire('Fait', 'Message a changer', 'success');
      }
    })
    .catch((err) => {
      if (err.response && err.response.status === 500) {
        console.log('ERROR');
        console.log(err);
        this.$globalSwal.serverError();
        // this.$globalSwal.error(
        //   'error.oops',
        //   err.response.data.message,
        //   'error.persists',
        // );
      } else {
        this.$globalSwal.serverError();
      }
    });
};
</script>
