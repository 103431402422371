<template>
  <b-row>
    <b-col
      cols="12"
      md="12"
      lg="12"
      class="mt-4 mb-4 nopadding"
    >
      <side-bar-menu :menu="$section().admin"></side-bar-menu>
      <idle-timer></idle-timer>
      <b-row class="nomargin">
        <b-col
          cols="9"
          class="pl-5 mb-2"
        >
          <h4 class="card-title">{{ $t('entity.manage', {name: shortname}) }}</h4>
          <h6 class="mb-2 text-muted">{{ $t('entity.descManage') }}</h6>
        </b-col>
        <b-col cols="3">
          <el-button
            type="text"
            icon="el-icon-arrow-left"
            class="float-right"
            @click="$router.go(-1)"
          >{{ $t('action.back') }}</el-button>
          <!-- <el-button
            type="text"
            icon="el-icon-arrow-left"
            class="float-right"
            @click="$router.push(`../entitylist`);"
          >{{ $t('action.back') }}</el-button> -->
        </b-col>

      </b-row>

      <div v-show="show">

        <b-row class="nomargin">
          <b-col
            cols="12"
            md="8"
            class="nopadding mb-2 pl-4"
          >
            <b-card>
              <template #header>
                <b-row>
                  <b-col>
                    <h4>{{ $t('entity.details') }}</h4>
                  </b-col>
                  <b-col>
                    <el-button
                      icon="el-icon-edit"
                      size="mini"
                      class="float-right"
                      @click="$router.push(`../entitycreateedit/${entityUuid}`);"
                    >{{ $t('action.edit') }}</el-button>

                  </b-col>
                </b-row>
              </template>

              <!-- <b-row>
                <b-col cols="3">
                  <h5>Id</h5>
                </b-col>
                <b-col cols="9">{{ entityId.digit5 }} <span class="font-weight-light">({{ entityUuid }})</span></b-col>
              </b-row>
              <hr> -->
              <b-row>
                <b-col cols="3">
                  <h5>{{ $t('entity.name') }}</h5>
                </b-col>
                <b-col cols="9">{{ name }} ({{ shortname }})</b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col cols="3">
                  <h5>{{ $t('entity.by') }}</h5>
                </b-col>
                <b-col cols="9">{{ user }}</b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col cols="3">
                  <h5>{{ $t('entity.the') }}</h5>
                </b-col>
                <b-col cols="9">{{ dateCreated }}</b-col>
              </b-row>

            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="nopadding"
          >
            <b-card>
              <template #header>
                <b-row>
                  <b-col>
                    <h4>{{ $t('entity.contains') }}</h4>
                  </b-col>
                </b-row>
              </template>
              <div class="text-center w-100">
                <span class="text-highlight-admin text-large-admin">{{ this.tableData.length }}</span>
                <h4 class="text-highlight-admin">{{ $tc('user.users', this.tableData.length).toUpperCase() }}</h4>
              </div>

            </b-card>
            <br>

            <el-button
              type="danger"
              @click="notReady()"
              icon="el-icon-delete"
              class="my-1 w-100"
            >{{ $t('action.delete').toUpperCase() }}</el-button>

          </b-col>
        </b-row>

        <b-card class="mb-2 mt-2 ml-2">
          <template #header>
            <b-row>
              <b-col>
                <h4>{{ $t('entity.usersInEntity') }}</h4>
              </b-col>
            </b-row>
          </template>
          <el-table
            :data="tableData"
            size="mini"
            class="w-100"
          >
            <el-table-column
              prop="acronym4digits"
              label="User"
              width="80"
            >
              <template slot-scope="scope">
                <el-avatar :class="`avatar avatar-${scope.row.nature}`"> {{ scope.row.name.acronym4digits }} </el-avatar> <br>

              </template>
            </el-table-column>
            <el-table-column
              prop="name.complete"
              label="Name"
            >
            </el-table-column>
            <el-table-column
              prop="status"
              :formatter="formatter_status"
              label="Status"
            >
            </el-table-column>
          </el-table>
        </b-card>
      </div>

    </b-col>
  </b-row>
</template>

<script>
import SideBarMenu from '../../components/SideBarMenu.vue';
import IdleTimer from '../../components/IdleTimer.vue';
import axiosResource from '@/utils/axiosResource';
import swal from 'sweetalert2';
const swalBootsrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary btn-swal',
    denyButton: 'btn btn-info btn-swal',
    cancelButton: 'btn btn-light btn-swal',
  },
  buttonsStyling: false,
});

export default {
  components: {
    SideBarMenu,
    IdleTimer,
  },
  data() {
    return {
      show: false,
      entityUuid: '',
      nature: '',
      type: '',
      name: '',
      shortname: '',
      dateCreated: '',
      dateUpdated: '',
      createdBy: '',
      user: '',
      tableData: [],
    };
  },
  mounted: function () {
    this.show = !this.show;
    this.entityUuid = this.$route.params.uuid;
    this.loadEntity();
  },
  methods: {
    notReady() {
      swalBootsrap.fire({
        title: 'Oops',
        text: this.$t('error.notReady'),
        showConfirmButton: true,
        icon: 'info',
      });
    },
    formatter_status(row) {
      return this.$tc(`status.${row.status}`);
    },
    usersInEntity() {
      console.log(this.entityId);
      axiosResource
        .get(`/admin/entities/${this.entityUuid}/users`)
        .then((response) => {
          //   console.log(response);
          this.tableData = response.data.results;
        })
        .catch((err) => {
          this.$globalSwal.error(err.response.data.msg);
        });
    },
    loadEntity() {
      axiosResource
        .get('/admin/entities/' + this.entityUuid)
        .then((response) => {
          this.$store.commit('loading', false);
          this.entityUuid = response.data.results.entity.uuid;
          this.name = response.data.results.entity.name;
          this.shortname = response.data.results.entity.shortname;
          this.dateCreated = response.data.results.entity.dateCreated;
          this.user = `${response.data.results.user.complete} (${response.data.results.user.acronym4digits})`;
        })
        .then(() => {
          this.usersInEntity();
        })
        .catch((err) => {
          this.$globalSwal.error(err.response.data.msg);
        });
    },
  },
};
</script>
<style scoped>
.text-highlight-admin {
  color: #67C23A;
}

.text-large-admin {
  font-size: 3.1rem;
}

</style>