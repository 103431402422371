<template>
  <b-modal
    id="modal-admin"
    title="Admin"
    button-size="sm"
  >
    <p>{{ $t('modal.admin.welcome') }}</p>
    <el-table
      :data="adminData"
      class="w-100"
      size="small"
    >
      <el-table-column
        prop="section"
        label="Section"
        width="70"
      >
        <template slot-scope="scope">
          <font-awesome-icon
            :icon="['fal', scope.row.section.icon]"
            class="fa-2x mr-2 text-danger"
          />
        </template>
      </el-table-column>

      <el-table-column
        prop="section"
        width="100"
      >
        <template slot-scope="scope">

          <b> {{ scope.row.section.name }}</b>

        </template>
      </el-table-column>
      <el-table-column
        prop="def"
        :label="$t('modal.admin.priviledge')"
      >
      </el-table-column>

    </el-table>
    <template #modal-footer="{ hide }">
      <b-button
        size="sm"
        variant="light"
        @click="hide()"
      >
        {{ $t('action.close') }}
      </b-button>
    </template>
  </b-modal>

</template>

<script>
export default {
  name: 'modal-nature',
  computed: {
    adminData() {
      return [
        {
          section: {
            name: this.formatSectionName(this.$tc('project.project', 2)),
            icon: 'code',
          },
          def: this.$t('modal.admin.readWrite'),
        },
        {
          section: {
            name: this.formatSectionName(this.$tc('entity.entity', 2)),
            icon: 'list-alt',
          },
          def: this.$t('modal.admin.readWrite'),
        },
        {
          section: {
            name: this.formatSectionName(this.$tc('user.users', 2)),
            icon: 'users',
          },
          def: this.$t('modal.admin.readWrite'),
        },
        {
          section: {
            name: this.formatSectionName(this.$tc('building.buildings', 2)),
            icon: 'building',
          },
          def: this.$t('modal.admin.readWrite'),
        },
        {
          section: {
            name: this.formatSectionName(this.$tc('category.category', 2)),
            icon: 'sitemap',
          },
          def: this.$t('modal.admin.readWrite'),
        },
        {
          section: {
            name: this.formatSectionName(this.$tc('definition.definition', 2)),
            icon: 'draw-square',
          },
          def: this.$t('modal.admin.readWrite'),
        },
      ];
    },
  },
  methods: {
    formatSectionName(name) {
      // Ensure the first letter is capitalized, and the rest are lower case
      return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    },
  },
};

// export default {
//   name: 'modal-nature',
//   data() {
//     return {
//       adminData: [
//         {
//           section: {
//             name: this.$tc('project.project', 2).toUpperCase(),
//             icon: 'code',
//           },
//           def: this.$t('modal.admin.readWrite'),
//         },
//         {
//           section: { name: 'Entities', icon: 'list-alt' },
//           def: this.$t('modal.admin.readWrite'),
//         },
//         {
//           section: { name: 'Users', icon: 'users' },
//           def: this.$t('modal.admin.readWrite'),
//         },
//         {
//           section: { name: 'Buildings', icon: 'building' },
//           def: this.$t('modal.admin.readWrite'),
//         },
//         {
//           section: { name: 'Categories', icon: 'sitemap' },
//           def: this.$t('modal.admin.readWrite'),
//         },
//         {
//           section: { name: 'Definitions', icon: 'draw-square' },
//           def: this.$t('modal.admin.readWrite'),
//         },
//       ],
//     };
//   },
// };
</script>
