<template>
  <div>
    <side-bar-menu :menu="$section().admin"></side-bar-menu>
    <idle-timer></idle-timer>
    <mobile-detected></mobile-detected>
    <b-row class="top-row border-bottom d-md-flex align-items-center">
      <b-col
        class="d-none d-md-block"
        cols="2"
        md="2"
      >
        <span class="custom-font-size-1rem"></span>
      </b-col>
      <b-col
        cols="8"
        md="6"
      >
        <el-input
          v-model="filterText"
          size="large"
          prefix-icon="el-icon-search"
          :placeholder="$t('components.typeToSearch')"
        />
      </b-col>
      <b-col
        cols="4"
        md="4"
      >
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        md="12"
        lg="12"
        class="mb-4 pb-4 border-bottom w-100"
      >

        <div class="mt-4 ml-4">
          <h1>{{ $t('test.russian') }}</h1><br>
          <h1>Her şeyi anlıyorum ve nedenini biliyorum</h1><br>
          <h1>我明白一切，我知道为什么</h1>
          <muted>Wǒ míngbái yīqiè, wǒ zhīdào wèishéme</muted><br><br>
          <h1>私はすべてを理解し、その理由を知っています</h1>
          <muted>Watashi wa subete o rikai shi, sono riyū o shitte imasu</muted><br><br>
          <h1>मैं सब कुछ समझता हूं और मुझे पता है क्यों</h1>
          <muted>main sab kuchh samajhata hoon aur mujhe pata hai kyon</muted><br><br>
          <h1>Ես ամեն ինչ հասկանում եմ և գիտեմ, թե ինչոււ</h1>
          <muted>Yes amen inch’ haskanum yem yev gitem, t’e inch’u</muted><br>
          <h1 class="float-right">أنا أفهم كل شيء وأعرف السبب</h1><br><br><br>
          <muted class="float-right">'ana 'afham kula shay' wa'aerif alsabab</muted>

        </div>
      </b-col>
    </b-row>
  </div>
</template>




<script>
import { mapMutations } from 'vuex';
import SideBarMenu from '../../components/SideBarMenu.vue';
import IdleTimer from '../../components/IdleTimer.vue';

export default {
  components: {
    SideBarMenu,
    IdleTimer,
  },
  data() {
    return {};
  },
  methods: {
    ...mapMutations(['SAVE_CONTEXT']),
  },
  mounted() {
    const { path } = this.$route;
    this.SAVE_CONTEXT(path);
  },
};
</script>